import React from "react"

import Layout from "../components/layout"
import heroBG from "../images/image01.jpg"
import SEO from "../components/seo"
import styled from "styled-components"

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  padding: 0;
  position: relative;
`

const HeroWrapper = styled.div`
  align-items: center;
  background-image: url(${heroBG});
  background-size: cover;
  background-position: center top;
  background-color: #4579b9;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
`

const Hero = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 80px 0;
  margin: 0 auto;
  max-width: 960px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    padding: 125px 0;
  }

  h1 {
    font-size: 36px;
    text-shadow: 2px 2px 4px #000;

    @media only screen and (min-width: 800px) {
      font-size: 50px;
    }
  }

  p {
    font-size: 20px;
    text-shadow: 1px 1px 2px #000;

    @media only screen and (min-width: 800px) {
      font-size: 22px;
    }
  }
`

const FeatureItems = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 50px 0;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  @media only screen and (min-width: 800px) {
    flex-direction: row;
  }
`

const FeatureItem = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 30px;
  width: 100%;

  @media only screen and (min-width: 800px) {
    width: 33%;
  }

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 16px;
  }

  svg {
    color: #4579b9;
    fill: #eff2fd;
    height: 40px;
    margin: 0 0 30px;
    width: 40px;
  }
`

const GetQuoteButton = styled.a`
  background-color: #4579b9;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 19px;
  font-weight: 700;
  padding: 12px 20px;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 16px rgba(#000, 0.1);
    cursor: pointer;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroWrapper>
      <Hero>
        <h1>Commercial Carpentry</h1>
        <p>The experts in steel stud framing and drywall</p>
        <GetQuoteButton
          target="_blank"
          href="mailto:info@blueskycarpenters.com?subject=Blue%20Sky%20Carpenters%20-%20Quote"
        >
          Get a Quote
        </GetQuoteButton>
      </Hero>
    </HeroWrapper>
    <MainContainer>
      <FeatureItems>
        <FeatureItem>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
          </svg>
          <h3>Problem Solvers</h3>
          <p>
            Years of construction experience has prepared us for a variety of
            different jobs. We pride ourselves in being able to think
            analytically to get you through even the most complex parts of a
            difficult project.
          </p>
        </FeatureItem>
        <FeatureItem>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          <h3>Customer Focused</h3>
          <p>
            Relationships are very important to us. All of our customers end up
            being long term customers. We have been doing business with many of
            our clients for over 20 years.
          </p>
        </FeatureItem>
        <FeatureItem>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
          </svg>
          <h3>Easy to Work With</h3>
          <p>
            From top to bottom we will work together to make your vision a
            reality. Clients keep coming back to us because of the quality of
            our leadership and our ability to bring a project together under
            strenuous deadlines.
          </p>
        </FeatureItem>
      </FeatureItems>
    </MainContainer>
  </Layout>
)

export default IndexPage
